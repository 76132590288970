import React from 'react'
import './index.scss'
const ErrorPage = () => {
    return (
        <div className="error-page">
        <h1>404:
            <small>Players Not Found</small></h1>
        </div>
    )
}

export default ErrorPage
