

import {createStore,combineReducers ,applyMiddleware,compose} from 'redux'
import { RootReducer} from './reducers'
import thunk from 'redux-thunk';
declare global {
    interface Window {
      __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
  }



const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(combineReducers({
    root:RootReducer
}),composeEnhancers(applyMiddleware(thunk)))


