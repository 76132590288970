import { useEffect, useState } from "react";

const useViewportWidth = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {

        const callback = () => setWidth(window.innerWidth)
        window.addEventListener("resize", callback);
    
        return () => window.removeEventListener("resize",callback);
      }, []);


    return {width}

    
}


export default useViewportWidth;