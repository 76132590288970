import { Reducer } from "react";
import { GlobalState, PageResponse, User } from "../../types";
import { GlobalActions, GlobalActionTypes } from "../actions";

export const InitialGlobalState: GlobalState = {
  status: false,
  user: null,
  currentHomePage: null,
  currentPage: null,
  error: null,
  darkMode: false,
};

export const RootReducer: Reducer<GlobalState, GlobalActions> = (
  state: GlobalState = InitialGlobalState,
  action: GlobalActions
) => {
  switch (action.type) {
    case GlobalActionTypes.UPDATE_APP_STATUS:
      return {
        ...state,
        status: action.status,
      };
    case GlobalActionTypes.ONBOARD_USER_SUCCESSFUL:

      const updated: User | null = state.user
        ? { ...state.user, 
          ...(action.name && {name: action.name}), 
          ...(action.database_id && {database_id: action.database_id}),
          socials : {
            ...state.user.socials,
            ...(action.twitter && {twitter: action.twitter}),
            ...(action.instagram && {instagram: action.instagram}),
            ...(action.buy_me_a_coffee && {buy_me_a_coffee: action.buy_me_a_coffee})
          }
         }
        : null;
      return {
        ...state,
        user: updated,
      };
    case GlobalActionTypes.GET_USER_FAILED:
      return {
        ...state,
        user: null,
      };
    case GlobalActionTypes.GET_USER_SUCCESSFUL:
      return {
        ...state,
        user: action.user,
        isLogging: false,
      };
    case GlobalActionTypes.GET_BLOG_SITE_SUCCESSFUL:
      const { type: actionType, ...homepage } = action;
      return {
        ...state,
        currentHomePage: { ...homepage },
      };
    case GlobalActionTypes.GET_BLOG_SITE_FAILED:
      return {
        ...state,
        error: {error: action.error, shouldRedirect: true},
      };
    case GlobalActionTypes.GET_PAGE_SUCESSFUL:
      const { type, ...page } = action;
      return {
        ...state,
        currentPage: { ...page },
      };
    case GlobalActionTypes.GET_PAGE_FAILED:
      return {
        ...state,
        error: {error: action.error, shouldRedirect: true},
      };
    case GlobalActionTypes.RESET_BLOG_SITE:
      return {
        ...state,
        currentHomePage: null,
      };
    case GlobalActionTypes.RESET_PAGE:
      return {
        ...state,
        currentPage: null,
      };
    case GlobalActionTypes.SET_DARK_MODE:
      return {
        ...state,
        darkMode: action.darkMode,
      };
    case GlobalActionTypes.PAGE_LIKE_SUCCESSFUL: {
      const updatedPage : PageResponse | null = state.currentPage ? {
        ...state.currentPage,
        likes : {
          no_of_likes: state.currentPage?.likes.no_of_likes + (action.liked ? 1 : -1),
          user_liked: action.liked
        }
      } : null
      return {
        ...state,
        currentPage :updatedPage 
      }
    }
    case GlobalActionTypes.PAGE_LIKE_FAILED:
      const updatedPage1 : PageResponse | null = state.currentPage ? {
        ...state.currentPage,
        likes : {
          no_of_likes:state.currentPage?.likes.no_of_likes ? state.currentPage?.likes.no_of_likes + (!state.currentPage.likes.user_liked ? 1 : -1) : 0,
          user_liked: !state.currentPage.likes.user_liked
        }
      } : null
      return {
        ...state,
        currentPage :updatedPage1 
      }
    case GlobalActionTypes.GET_PAGE_LIKES_SUCCESSFUL: {
      const updatedPage : PageResponse | null = state.currentPage ? {
        ...state.currentPage,
        likes : {
          no_of_likes:state.currentPage?.likes.no_of_likes,
          user_liked: action.liked
        }
      } : null
      return {
        ...state,
        currentPage :updatedPage 
      }
    }
      
    default:
      return state;
  }
};
