import { notification } from "antd";
import { Action, ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { getBlogPage, getPage, getPageLikes, getUser, likePage, onboardUser } from "../../service";
import { GlobalState, HomePageResponse, PageResponse, UpdateUserPayload, User } from "../../types";
import { deleteCookie, getCookie } from "../../utils";

export enum GlobalActionTypes {
  UPDATE_APP_STATUS = "UPDATE_APP_STATUS",
  CONNECT_TO_NOTION_REQUESTED = "CONNECT_TO_NOTION_REQUESTED",
  CONNECT_TO_NOTION_SUCCESSFUL = "CONNECT_TO_NOTION_SUCCESSFUL",
  CONNECT_TO_NOTION_FAILED = "CONNECT_TO_NOTION_FAILED",
  NOTION_AUTH_CALLBACK_REQUESTED = "NOTION_AUTH_CALLBACK_REQUESTED",
  NOTION_AUTH_CALLBACK_SUCCESSFUL = "NOTION_AUTH_CALLBACK_SUCCESSFUL",
  NOTION_AUTH_CALLBACK_FAILED = "NOTION_AUTH_CALLBACK_FAILED",
  ONBOARD_USER_REQUESTED = "ONBOARD_USER_REQUESTED",
  ONBOARD_USER_SUCCESSFUL = "ONBOARD_USER_SUCCESSFUL",
  ONBOARD_USER_FAILED = "ONBOARD_USER_FAILED",
  GET_USER_REQUESTED = "GET_USER_REQUESTED",
  GET_USER_SUCCESSFUL = "GET_USER_SUCCESSFUL",
  GET_USER_FAILED = "GET_USER_FAILED",
  GET_BLOG_SITE_REQUESTED = "GET_BLOG_SITE_REQUESTED",
  GET_BLOG_SITE_SUCCESSFUL = "GET_BLOG_SITE_SUCCESSFUL",
  GET_BLOG_SITE_FAILED = "GET_BLOG_SITE_FAILED",
  GET_PAGE_REQUESTED = "GET_PAGE_REQUESTED",
  GET_PAGE_FAILED = "GET_PAGE_FAILED",
  GET_PAGE_SUCESSFUL = "GET_PAGE_SUCESSFUL",
  RESET_PAGE = "RESET_PAGE",
  RESET_BLOG_SITE = "RESET_BLOG_SITE",
  SET_DARK_MODE = "SET_DARK_MODE",
  PAGE_LIKE_REQUESTED="PAGE_LIKE_REQUESTED",
  PAGE_LIKE_SUCCESSFUL="PAGE_LIKE_SUCCESSFUL",
  PAGE_LIKE_FAILED="PAGE_LIKE_FAILED",
  GET_PAGE_LIKES_REQUESTED="GET_PAGE_LIKES_REQUESTED",
  GET_PAGE_LIKES_SUCCESSFUL="GET_PAGE_LIKES_SUCCESSFUL",
  GET_PAGE_LIKES_FAILED="GET_PAGE_LIKES_FAILED",
}

export type UpdateAppStatus = Action<GlobalActionTypes.UPDATE_APP_STATUS> & {
  status: boolean;
};

export type ConnectToNotionRequestedAction =
  Action<GlobalActionTypes.CONNECT_TO_NOTION_REQUESTED>;
export type ConnectToNotionSuccessfulAction =
  Action<GlobalActionTypes.CONNECT_TO_NOTION_SUCCESSFUL>;
export type ConnectToNotionFailedAction =
  Action<GlobalActionTypes.CONNECT_TO_NOTION_FAILED> & { error: string };

export type NotionAuthCallbackRequestedAction =
  Action<GlobalActionTypes.NOTION_AUTH_CALLBACK_REQUESTED>;
export type NotionAuthCallbackSuccessfulAction =
  Action<GlobalActionTypes.NOTION_AUTH_CALLBACK_SUCCESSFUL> & {
    isLogged: boolean;
  };
export type NotionAuthCallbackFailedAction =
  Action<GlobalActionTypes.NOTION_AUTH_CALLBACK_FAILED> & { error: string };

export type OnboardUserRequestedAction =
  Action<GlobalActionTypes.ONBOARD_USER_REQUESTED>;
export type OnboardUserSuccessfulAction =
  Action<GlobalActionTypes.ONBOARD_USER_SUCCESSFUL> & UpdateUserPayload;
export type OnboardUserFailedAction =
  Action<GlobalActionTypes.ONBOARD_USER_FAILED> & { error: string };

export type GetUserRequestedAction =
  Action<GlobalActionTypes.GET_USER_REQUESTED>;
export type GetUserSuccessfulAction =
  Action<GlobalActionTypes.GET_USER_SUCCESSFUL> & { user: User };
export type GetUserFailedAction = Action<GlobalActionTypes.GET_USER_FAILED> & {
  error: string;
};

export type GetBlogSiteRequestedAction =
  Action<GlobalActionTypes.GET_BLOG_SITE_REQUESTED>;
export type GetBlogSiteSuccessfulAction =
  Action<GlobalActionTypes.GET_BLOG_SITE_SUCCESSFUL> & HomePageResponse;
export type GetBlogSiteFailedAction =
  Action<GlobalActionTypes.GET_BLOG_SITE_FAILED> & { error: string };

export type GetPageRequestedAction =
  Action<GlobalActionTypes.GET_PAGE_REQUESTED>;
export type GetPageSuccessfulAction =
  Action<GlobalActionTypes.GET_PAGE_SUCESSFUL> & PageResponse;
export type GetPageFailedAction = Action<GlobalActionTypes.GET_PAGE_FAILED> & {
  error: string;
};

export type ResetPageAction = Action<GlobalActionTypes.RESET_PAGE>;
export type ResetBlogSiteAction = Action<GlobalActionTypes.RESET_BLOG_SITE>;
export type SetDarkModeAction = Action<GlobalActionTypes.SET_DARK_MODE> & {
  darkMode: boolean;
};

export type PageLikeRequestedAction = Action<GlobalActionTypes.PAGE_LIKE_REQUESTED>;
export type PageLikeSuccessfulAction = Action<GlobalActionTypes.PAGE_LIKE_SUCCESSFUL> & {
  liked: boolean;
};
export type PageLikeFailedAction = Action<GlobalActionTypes.PAGE_LIKE_FAILED>;


export type GetPageLikesRequestedAction = Action<GlobalActionTypes.GET_PAGE_LIKES_REQUESTED>;
export type GetPageLikesSuccessfulAction = Action<GlobalActionTypes.GET_PAGE_LIKES_SUCCESSFUL> & {
  liked: boolean;
};
export type GetPageLikesFailedAction = Action<GlobalActionTypes.GET_PAGE_LIKES_FAILED> & {
  error: string;
};

export type GlobalActions =
  | UpdateAppStatus
  | ConnectToNotionFailedAction
  | ConnectToNotionRequestedAction
  | ConnectToNotionSuccessfulAction
  | NotionAuthCallbackFailedAction
  | NotionAuthCallbackRequestedAction
  | NotionAuthCallbackSuccessfulAction
  | OnboardUserRequestedAction
  | OnboardUserSuccessfulAction
  | OnboardUserFailedAction
  | GetUserRequestedAction
  | GetUserSuccessfulAction
  | GetUserFailedAction
  | GetBlogSiteRequestedAction
  | GetBlogSiteSuccessfulAction
  | GetBlogSiteFailedAction
  | GetPageRequestedAction
  | GetPageSuccessfulAction
  | GetPageFailedAction
  | ResetPageAction
  | ResetBlogSiteAction
  | SetDarkModeAction
  | PageLikeRequestedAction
  | PageLikeSuccessfulAction
  | PageLikeFailedAction
  | GetPageLikesRequestedAction
  | GetPageLikesSuccessfulAction
  | GetPageLikesFailedAction;
export interface AppDispatch {
  updateAppStatus: ActionCreator<
    ThunkAction<
      UpdateAppStatus,
      GlobalState,
      {
        value: boolean;
      },
      UpdateAppStatus
    >
  >;
  connectToNotion: ActionCreator<
    ThunkAction<
      ConnectToNotionSuccessfulAction,
      GlobalState,
      null,
      ConnectToNotionSuccessfulAction
    >
  >;
  notionAuthCallback: ActionCreator<
    ThunkAction<
      Promise<NotionAuthCallbackSuccessfulAction>,
      GlobalState,
      { code: string },
      NotionAuthCallbackSuccessfulAction
    >
  >;
  onboardUser: ActionCreator<
    ThunkAction<
      Promise<OnboardUserSuccessfulAction>,
      GlobalState,
      UpdateUserPayload,
      OnboardUserSuccessfulAction
    >
  >;
  getUser: ActionCreator<
    ThunkAction<
      Promise<GetUserSuccessfulAction>,
      GlobalState,
      null,
      GetUserSuccessfulAction
    >
  >;
  getHomePage: ActionCreator<
    ThunkAction<
      Promise<GetBlogSiteSuccessfulAction>,
      GlobalState,
      { name: string },
      GetBlogSiteSuccessfulAction
    >
  >;
  getBlogPage: ActionCreator<
    ThunkAction<
      Promise<GetPageSuccessfulAction>,
      GlobalState,
      { name: string; id: string },
      GetPageSuccessfulAction
    >
  >;
  resetPage: ActionCreator<
    ThunkAction<Promise<ResetPageAction>, GlobalState, null, ResetPageAction>
  >;
  resetBlogPage: ActionCreator<
    ThunkAction<
      Promise<ResetBlogSiteAction>,
      GlobalState,
      { darkMode: boolean },
      ResetBlogSiteAction
    >
  >;
  setDarkMode: ActionCreator<
    ThunkAction<
      Promise<SetDarkModeAction>,
      GlobalState,
      null,
      SetDarkModeAction
    >
  >,
  likePost: ActionCreator<
  ThunkAction<
      Promise<PageLikeSuccessfulAction>,
      GlobalState,
      {value: boolean,user: string, pageId: string},
      PageLikeSuccessfulAction
  >
  >,
  getLikes: ActionCreator<
      ThunkAction<
        Promise<GetPageLikesSuccessfulAction>,
        GlobalState,
        {subDomain: string, pageId: string},
        GetPageLikesSuccessfulAction
      >
  >
}

export const AppActionCreator: AppDispatch = {
  updateAppStatus: (value: boolean) => {
    return (dispatch: Dispatch) => {
      let updateAppStatus: UpdateAppStatus = {
        type: GlobalActionTypes.UPDATE_APP_STATUS,
        status: true,
      };
      return dispatch(updateAppStatus);
    };
  },
  connectToNotion: () => {
    return (dispatch: Dispatch) => {
      dispatch({ type: GlobalActionTypes.CONNECT_TO_NOTION_REQUESTED });
      return dispatch({ type: GlobalActionTypes.CONNECT_TO_NOTION_SUCCESSFUL });
    };
  },
  notionAuthCallback: (code: string) => {
    return async (
      dispatch: Dispatch
    ): Promise<NotionAuthCallbackSuccessfulAction> => {
      try {
        dispatch({ type: GlobalActionTypes.NOTION_AUTH_CALLBACK_REQUESTED });
        const m: NotionAuthCallbackSuccessfulAction = {
          type: GlobalActionTypes.NOTION_AUTH_CALLBACK_SUCCESSFUL,
          isLogged: true,
        };
        return dispatch(m);
      } catch (err) {
        console.error(err);
        deleteCookie("access_token");
        const notionAuthCallbackFailedAction: NotionAuthCallbackFailedAction = {
          type: GlobalActionTypes.NOTION_AUTH_CALLBACK_FAILED,
          error: `${err}`,
        };
        dispatch(notionAuthCallbackFailedAction);
        return Promise.reject(err);
      }
    };
  },
  onboardUser: (payload: UpdateUserPayload) => {
    return async (dispatch: Dispatch): Promise<OnboardUserSuccessfulAction> => {
      try {
        let onboardUserRequestedAction: OnboardUserRequestedAction = {
          type: GlobalActionTypes.ONBOARD_USER_REQUESTED,
        };
        dispatch(onboardUserRequestedAction);
        // some service calls;
        await onboardUser({...payload});
        let onboardUserSuccessfulAction: OnboardUserSuccessfulAction = {
          type: GlobalActionTypes.ONBOARD_USER_SUCCESSFUL,
          ...payload,
        };
        return dispatch(onboardUserSuccessfulAction);
      } catch (err) {
        console.error(err);
        dispatch({
          type: GlobalActionTypes.ONBOARD_USER_FAILED,
          error: `${err}`,
        });
        return Promise.reject(err);
      }
    };
  },
  getUser: () => {
    return async (dispatch: Dispatch): Promise<GetUserSuccessfulAction> => {
      try {
        let getUserRequestedAction: GetUserRequestedAction = {
          type: GlobalActionTypes.GET_USER_REQUESTED,
        };
        dispatch(getUserRequestedAction);
        //some service calls
        const result = await getUser();

        let getUserSuccessfulAction: GetUserSuccessfulAction = {
          type: GlobalActionTypes.GET_USER_SUCCESSFUL,
          user: { ...result.user },
        };
        return dispatch(getUserSuccessfulAction);
      } catch (err) {
        console.error(err);
        deleteCookie("access_token");
        dispatch({ type: GlobalActionTypes.GET_USER_FAILED, error: `${err}` });
        return Promise.reject(err);
      }
    };
  },
  getHomePage: (name: string) => {
    return async (dispatch: Dispatch) => {
      try {
        let getBlogSiteRequestedAction: GetBlogSiteRequestedAction = {
          type: GlobalActionTypes.GET_BLOG_SITE_REQUESTED,
        };
        dispatch(getBlogSiteRequestedAction);
        const response = await getBlogPage(name);
        const getBlogSiteSuccessfulAction: GetBlogSiteSuccessfulAction = {
          type: GlobalActionTypes.GET_BLOG_SITE_SUCCESSFUL,
          ...response,
        };
        return dispatch(getBlogSiteSuccessfulAction);
      } catch (err) {
        console.error(err);
        const getBlogSiteFailedAction: GetBlogSiteFailedAction = {
          type: GlobalActionTypes.GET_BLOG_SITE_FAILED,
          error: `${err}`,
        };
        dispatch(getBlogSiteFailedAction);
        return Promise.reject(err);
      }
    };
  },
  getBlogPage: (name: string, id: string) => {
    return async (dispatch: Dispatch) => {
      try {
        let getPageRequestedAction: GetPageRequestedAction = {
          type: GlobalActionTypes.GET_PAGE_REQUESTED,
        };
        dispatch(getPageRequestedAction);
        const response = await getPage(name, id);
        const getPageSuccessfulAction: GetPageSuccessfulAction = {
          type: GlobalActionTypes.GET_PAGE_SUCESSFUL,
          ...response,
        };
        return dispatch(getPageSuccessfulAction);
      } catch (err) {
        console.error(err);
        const getPageFailedAction: GetPageFailedAction = {
          type: GlobalActionTypes.GET_PAGE_FAILED,
          error: `${err}`,
        };
        dispatch(getPageFailedAction);
        return Promise.reject(err);
      }
    };
  },
  resetBlogPage: () => {
    return async (dispatch: Dispatch) => {
      let resetBlogPageAction: ResetBlogSiteAction = {
        type: GlobalActionTypes.RESET_BLOG_SITE,
      };
      return dispatch(resetBlogPageAction);
    };
  },
  resetPage: () => {
    return async (dispatch: Dispatch) => {
      let resetPageAction: ResetPageAction = {
        type: GlobalActionTypes.RESET_PAGE,
      };
      return dispatch(resetPageAction);
    };
  },
  setDarkMode: (darkMode: boolean) => {
    return async (dispatch: Dispatch) => {
      let setDarkModeAction: SetDarkModeAction = {
        type: GlobalActionTypes.SET_DARK_MODE,
        darkMode,
      };
      return dispatch(setDarkModeAction);
    };
  },
  likePost: (value: boolean,user:string, pageId: string) => {
    return async (dispatch: Dispatch) => {
      try {
        const pageLikeRequestedAction : PageLikeRequestedAction = {
          type: GlobalActionTypes.PAGE_LIKE_REQUESTED
        };

        dispatch(pageLikeRequestedAction);
        let pageLikeSuccessfulAction : PageLikeSuccessfulAction = {
          type: GlobalActionTypes.PAGE_LIKE_SUCCESSFUL,
          liked: value
        };
        const res = dispatch(pageLikeSuccessfulAction);
        await likePage(user,pageId);
        return res;
      }catch(err) {
        notification.error({message: "Failed to like the post, please login again to do"});
        if(getCookie("access_token"))deleteCookie("access_token");
        let pageLikeFailedAction : PageLikeFailedAction = {
          type: GlobalActionTypes.PAGE_LIKE_FAILED
          
        };
        dispatch(pageLikeFailedAction);
        return Promise.reject();
      }
    }
  },
  getLikes: (subDomain : string, pageId: string) => {
    return async (dispatch: Dispatch) => {
      try {
        const getPageLikesRequestedAction : GetPageLikesRequestedAction = {
          type:GlobalActionTypes.GET_PAGE_LIKES_REQUESTED
        }
        dispatch(getPageLikesRequestedAction);
        const res = await getPageLikes(subDomain,pageId);
        const getPageLikesSuccessfuldAction : GetPageLikesSuccessfulAction = {
          type:GlobalActionTypes.GET_PAGE_LIKES_SUCCESSFUL,
          liked: res.user_liked
        }
        return dispatch(getPageLikesSuccessfuldAction);

      }catch(err) {
        console.error(err);
        const getPageLikesFailedAction: GetPageLikesFailedAction = {
          type: GlobalActionTypes.GET_PAGE_LIKES_FAILED,
          error: `${err}`,
        };
        dispatch(getPageLikesFailedAction);
        return Promise.reject(err);
      }
    }
  }
};
