


export const isAppUsingSubDomains = (subdomain: string) => subdomain !== "notiondiary" && subdomain!=="localhost" && subdomain!=="www"

export const isBlogPage = (host: string, path: string) => (!isAppUsingSubDomains(host) && !!path.match(/\/.+\/.+/g)) || (isAppUsingSubDomains(host) && !path.includes("admin") && !path.includes("auth") && !!path.match(/\/.+\//g))




export const setCookie = (name: string,value: string,exp_days: number) => {
    var d = new Date();
    d.setTime(d.getTime() + (exp_days*24*60*60*1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}



export const getCookie = (name: string) => {
    var cname = name + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i < ca.length; i++){
        var c = ca[i];
        while(c.charAt(0) === ' '){
            c = c.substring(1);
        }
        if(c.indexOf(cname) === 0){
            return c.substring(cname.length, c.length);
        }
    }
    return "";
}


export const deleteCookie = (name: string) =>{
    var d = new Date();
    d.setTime(d.getTime() - (60*60*1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = name + "=;" + expires+ ";path=/";
}