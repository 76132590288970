import axios from "axios";
import { HomePageResponse, PageResponse, UpdateUserPayload, User } from "../types";
import { getCookie } from "../utils";

export const test = () => console.log("hi");

export const getUser = async (): Promise<{ user: User }> => {
  try {
    const api = axios.create({
      baseURL: process.env.REACT_APP_SERVER_URL || "http://localhost:9000",
      headers: {
        "x-access-token": getCookie("access_token") ?? "",
      },
    });
    const response = await api({ method: "GET", url: "/user" });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }

  //get access_token from localstorage,
  // send request
};

export const onboardUser = async (
  payload: UpdateUserPayload
): Promise<boolean> => {
  try {
    const api = axios.create({
      baseURL: process.env.REACT_APP_SERVER_URL || "http://localhost:9000",
      headers: {
        "x-access-token": getCookie("access_token") ?? "",
      },
    });
    const response = await api({
      method: "PUT",
      url: "/user",
      data: { ...payload},
    });
    return response.data === "Success";
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const getBlogPage = async (user: string): Promise<HomePageResponse> => {
  try {
    const api = axios.create({
      baseURL: process.env.REACT_APP_SERVER_URL || "http://localhost:9000",
      headers: {
        "x-access-token": getCookie("access_token") ?? "",
      },
    });
    const response = await api({ method: "GET", url: `/${user}` });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const getPage = async (
  user: string,
  id: string
): Promise<PageResponse> => {
  try {
    const api = axios.create({
      baseURL: process.env.REACT_APP_SERVER_URL || "http://localhost:9000",
      headers: {
        "x-access-token": getCookie("access_token") ?? "",
      },
    });
    const response = await api({ method: "GET", url: `/${user}/${id}` });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};


export const likePage = async (
  user: string,
  id: string,
): Promise<void> => {
  try {
    const api = axios.create({
      baseURL: process.env.REACT_APP_SERVER_URL || "http://localhost:9000",
      headers: {
        "x-access-token": getCookie("access_token") ?? "",
      },
    });
    await api({ method: "POST", url: `/${user}/${id}/like` });
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};


export const getPageLikes = async (
  user: string,
  id: string,
): Promise<{user_liked: boolean}> => {
  try {
    const api = axios.create({
      baseURL: process.env.REACT_APP_SERVER_URL || "http://localhost:9000",
      headers: {
        "x-access-token": getCookie("access_token") ?? "",
      },
    });
    const response = await api({ method: "GET", url: `/${user}/${id}/likes` });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};
