import { notification } from 'antd'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { GlobalState } from '../../types'

export const ErrorBoundary:React.FC = (props) => {

    const history = useHistory();

    const {appError} = useSelector((state: {root: GlobalState}) => {
        return {
            appError: state.root.error
        }
    })
    useEffect(() => {
        if(appError && appError.error) {
            notification.error({message: appError.error})
            if(appError.shouldRedirect) {
                history.push("/error");
           
            }
        }
    },[appError,history]);
    return (
        <div >
        {props.children}
        </div>   
    )
}
