import React from 'react'

const Footer = () => {
    return (
        <div className="np-footer d-flex justify-content-center align-items-center p-4">
            <span>
            Made in love with <b>Notion ❤️</b>  by <a href="https://lakshyabatman.github.io" target="_blank" rel="noreferrer" >Lakshya</a> and <a href="https://www.behance.net/saurabh81098?" target="_blank" rel="noreferrer">Saurabh</a>
            </span>
        </div>
    )
}

export default Footer
