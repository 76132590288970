import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import useDispatch from '../../Hooks/useDispatch';
import { AppActionCreator } from '../../store/actions';
import { setCookie } from '../../utils';

const NotionAuthCalllback = () => {

    const history = useHistory();
    const dispatch =useDispatch();

    useEffect(() => {
        

        if(window.location.search) {
            let queryStringArray = window.location.search.substring(1).split('&').find(pair => {
                return pair.split("=").length && pair.split("=")[0] === "access_token"  
            })
            
            if(queryStringArray) {
                let accessToken = queryStringArray.split("=").length >1 ? queryStringArray.split("=")[1] : null; 

                if(accessToken) setCookie("access_token", accessToken,10);             
                if(accessToken) {
                    dispatch(AppActionCreator.notionAuthCallback(accessToken))
                    history.push("/admin")
                    return;
                }  
                
            }
            
        }   
        history.push("/auth")
    },[dispatch,history])

    return (
        <div>
            callback
        </div>
    )
}

export default NotionAuthCalllback
