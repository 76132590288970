import React, { useEffect } from 'react'
import { Button, Card } from 'react-bootstrap'
import Layout from '../../shared/Layout/Layout'
import NotionLogo from '../../Assets/NotionLogo.png'
import useDispatch from '../../Hooks/useDispatch'
import { AppActionCreator } from '../../store/actions'
import { useHistory } from 'react-router'
import './index.scss'
import { getCookie } from '../../utils'
const Login = () => {

    const dispatch = useDispatch();

    const history = useHistory();


    const onClick = () => {
        dispatch(AppActionCreator.connectToNotion());
        if(process.env.REACT_APP_SERVER_URL) window.location.href =`${process.env.REACT_APP_SERVER_URL}/notion/authorize`
    }
    

    useEffect(() => {
        if(!!!getCookie("access_token")) {
            dispatch(AppActionCreator.getUser()).then(() => history.push("/admin"))
        }
    },[dispatch,history])

    


    return (
        <Layout title={(<h3 className="mb-3 "><b>Welcome to Notion Diary</b></h3>)}>
            <div>
                <Card className='np-blog-card-2'>
                    <Card.Body className="d-flex flex-column justify-content-center align-items-center p-4 px-5 mx-5">
                     <h5 className="mb-4">To get started 💎</h5>
                    <Button className="d-flex p-2 px-4 align-items-center animated-cta " onClick={onClick}>
                        <p className="mb-0">Connect with</p>
                        <img src={NotionLogo} className="icon ms-2" alt="Notion"/>
                    </Button>
                    <p className='mt-3' >To know more, <a href='https://nonstop-heart-099.notion.site/Welcome-to-Notion-Diary-fa99cdadc9a149f58bf8004022401939' target="_blank" rel="noreferrer"> Click here</a></p>
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default Login
