import { Drawer } from 'antd';
import React, { useState } from 'react'
import {Container, Nav, Navbar,Button} from 'react-bootstrap';
import useViewportWidth from '../../Hooks/useViewportWidth';
import {ReactComponent as NotionDiary} from '../../Assets/Notion_Diary.svg';
import {ReactComponent as Sun} from '../../Assets/Sun.svg';
import {ReactComponent as Moon} from '../../Assets/Moon.svg';
import {ReactComponent as Hamburger} from '../../Assets/MenuHamburger.svg';
import './index.scss';
import { isBlogPage } from '../../utils';
import { useLocation } from 'react-router';

interface Props  {
  setDarkMode: () => void;
  dark: boolean
}
const BREAK_WIDTH = 680;

const ActionMenu = (props : {setDarkMode: () => void, dark: boolean}) => {

  const {width} = useViewportWidth();


  const location = useLocation()


  const isAtBlogPage = !!isBlogPage(window.location.hostname , location.pathname)


  const isAdminPage = location.pathname.includes("admin")



  return (
    <>
    <Nav className="me-auto" >
    <Nav.Link href="https://lakshya.notiondiary.xyz/5c2bcb97-d6ee-4542-949b-f3899e0afa7c" style={{color:"inherit", fontSize:12}} className={`${width > BREAK_WIDTH ?'mx-4' :'mt-3'}`}>Features</Nav.Link>
    <Nav.Link href="https://lakshya.notiondiary.xyz/f4241911-1606-4655-863a-4608eb741020" style={{color:"inherit",fontSize:12}}>How it works</Nav.Link>
    </Nav>
    <div className={`${width <= BREAK_WIDTH ?'mt-4' :''}`}>
      {width > BREAK_WIDTH ? (props.dark ? <Sun className='cursor me-3 toggle-view-icon' onClick={props.setDarkMode}/> : <Moon className='cursor me-3 toggle-view-icon' onClick={props.setDarkMode}/>) : null}
      {(!isAtBlogPage) && (!isAdminPage && (<Button className='cta no-border ' href='/admin'>Begin {'->'}</Button>)) }
    </div>
    </>
  )
}

const Header: React.FC<Props> = (props) => {

  const {width} = useViewportWidth()

  const [drawerVisible, setDrawerVisible] = useState(false);
  
  const setDrawerState= (value: boolean) => () => setDrawerVisible(value)

    return (

        <div>

          <Navbar className="np-navbar py-3" fixed='top'>
                  <Container className='w-80'>
                    
                    <Navbar.Brand style={{color:"inherit"}} href='https://www.notiondiary.xyz/'>
                      <NotionDiary className='notion_diary_logo'/>
                    </Navbar.Brand>
                    {
                      width > BREAK_WIDTH ?
                      <ActionMenu setDarkMode={props.setDarkMode} dark={props.dark}/> : <div>
                            {props.dark ? <Sun className='cursor me-3 toggle-view-icon' onClick={props.setDarkMode}/> : <Moon className='cursor me-3 toggle-view-icon' onClick={props.setDarkMode}/>}
                            <Hamburger className='menu-hamburger' onClick={setDrawerState(!drawerVisible)}/>
                            <Drawer title="Menu" placement="right" onClose={setDrawerState(false)} visible={drawerVisible}>
                              <ActionMenu dark={props.dark} setDarkMode={props.setDarkMode}/>
                            </Drawer>
                          </div>
                    }
                  </Container>
                </Navbar>
        </div>
    )
}

export default Header
