import React, { ReactElement } from 'react'


import './index.scss';

const Layout: React.FC<{title?: ReactElement}>= ({title, children}) => {

    return (
        <div className="layout-box">
            <div className="layout-inner-box">
                <div>{title}</div>
                <div className="layout-content">
                    {children}
                </div>
            </div>
        </div>
    )
}


export default Layout