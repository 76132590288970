import { notification, Spin } from 'antd';
import React, { Suspense, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, RouteComponentProps } from 'react-router-dom';
import Auth from './pages/auth/Auth';
import NotionAuthCalllback from './pages/auth/NotionAuthCalllback';
import ErrorPage from './pages/error/ErrorPage';
import { ErrorBoundary } from './shared/ErrorBoundary/ErrorBoundary';
import Footer from './shared/Footer/Footer';
import Header from './shared/Header/Header';
import { store } from './store';
import { AppWindow } from './types';

const WebPage = React.lazy(() => import('./pages/web')) // website route
const AdminPage = React.lazy(() => import('./pages/admin')) //admin page
const BlogPage = React.lazy(() => import('./pages/blog')) // sigle blog page
const HomePage = React.lazy(() => import('./pages/home')) // user blogsite
declare let window: AppWindow;
notification.config({
  placement: 'bottomRight',
  bottom: 50,
  duration: 3,
});

const App = () => {


  
  const [darkMode, setDarkMode] = useState(false);

  useEffect(()=> {
    if(window.Beamer) window.Beamer.init()
  },[])
  
  return (
    <>
    <Provider store={store} >
      <Suspense fallback={(
        <div style={{height:'100vh', width:'100%'}} className="d-flex justify-content-center align-items-center ">
          <Spin tip="Loading..."/>
        </div>
        
     
      )}>
      <div className={`app-wrapper ${darkMode ? 'dark ' :''}`} >
      <BrowserRouter>     
      <Header setDarkMode={() => setDarkMode(!darkMode)} dark={darkMode}/>

       <ErrorBoundary>
       <Route path="/:subdomain" component={(props: RouteComponentProps<{subdomain: string}>) => {
        // check for subdomain
        if(props.match.params.subdomain && props.match.params.subdomain==="error" ) return <ErrorPage/>
        if(props.match.params.subdomain && props.match.params.subdomain==="auth" ) return <Auth/>
        if(props.match.params.subdomain && props.match.params.subdomain==="admin") return <AdminPage/>
        
        const subdomain = window.location.hostname.split(".").shift()
        if(subdomain && (subdomain !== "notiondiary" && subdomain!=="localhost" && subdomain!=="www")) {

          return <BlogPage subDomain={subdomain} pageId={props.match.params.subdomain}/>
        }


        
      return <HomePage />
    }} exact/>

    
    <Route path="/:subdomain/:page_id" component={(props: RouteComponentProps<{subdomain: string, page_id: string}>) => {
      
      if(props.match.params.subdomain && props.match.params.subdomain==="auth" && props.match.params.page_id==="callback") return <NotionAuthCalllback/>
      return <BlogPage/>
    }} exact/>  

    <Route path="/" component={() => {
      // check for subdomain
      const subdomain = window.location.hostname.split(".").shift()
      if(subdomain && (subdomain !== "notiondiary" && subdomain!=="localhost" && subdomain!=="www")) {
        return <HomePage subdomain={subdomain} />
      }
      return (
        <WebPage darkMode={darkMode}/>
      )
    }} exact/>
       </ErrorBoundary>

      
      <Footer />
      </BrowserRouter>
      </div>
      </Suspense>
    </Provider>
    </>
  );
}

export default App;
